import { useRouter } from 'next/router';
import { useContext, useState } from 'react'
import { AppContext } from '../frontend/AppContext';

export function MainSearchField(props: { q?: string }) {
    const [q, setQ] = useState(props.q);
    const appContext = useContext(AppContext);
    const router = useRouter();
    const curLng = appContext?.tenant.currentLocale;

    const handleSubmit = () => {
        router.push({
            pathname: `/[language]/search`,
            query: { q, language: curLng },
        })
    }

    return <div className="w-full relative">
        <form action={`${curLng}/search`} method='get' onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
            <input
                type="text"
                name='q'
                value={q}
                placeholder={appContext?.tenant.texts.searchPlaceholderText}
                onChange={(e) => setQ(e.target.value)}
                className="transition-shadow text-2xl p-5 pr-20 rounded-3xl w-full shadow-nice-soft focus:outline-none focus:shadow-lg" />

            <div className='absolute top-0 right-2 grid place-items-center bottom-0'>
                <button type='submit' className='rounded-full aspect-square w-[55px] grid place-items-center cursor-pointer transition-all'>
                    <i className="fa-solid fa-magnifying-glass"></i>
                </button>
            </div>
        </form>
    </div>
}

export function ArticleDetailSearchField(props: { className?: string }) {
    const [q, setQ] = useState('');
    const appContext = useContext(AppContext);
    const router = useRouter();
    const curLng = appContext?.tenant.currentLocale;

    const handleSubmit = () => {
        router.push({
            pathname: `/[language]/search`,
            query: { q, language: curLng }
        })
    }

    return <div className={`w-full relative ${props.className}`}>
        <form action={`${curLng}/search`} method='get' onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
            <input
                type="text"
                name='q'
                placeholder={appContext?.tenant.texts.searchPlaceholderText}
                onChange={(e) => setQ(e.target.value)}
                className="p-3 pr-10 border border-primary rounded-md w-full focus:outline-none focus:border-primary-bg" />

            <div className='absolute top-0 right-2 grid place-items-center bottom-0'>
                <button type='submit' className='grid place-items-center text-gray-500 cursor-pointer hover:text-gray-300 transition-all'>
                    <i className="fa-solid fa-magnifying-glass"></i>
                </button>
            </div>
        </form>
    </div>
}